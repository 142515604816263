<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 5" class="textSection">
        <p class="textSection1 textBlue" v-html="$t('textSection1')"></p>
        <p class="textSection1 textGrey" v-html="$t('textSection2')"></p>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" class="textSection">
        <p class="textSection1 textGrey" v-html="$t('textSection3')"></p>
        <p class="textSection1 textBlue" v-html="$t('textSection4')"></p>
        <v-btn class="contactUsBtn"
               rounded
               v-if="!mobile"
               height="65px"
               dark
               v-html="$t('contactUs')"
               @click="$router.push({name: 'Contact'}).then(() => {$vuetify.goTo(0)})"
               color="#57939E">
        </v-btn>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'PhysicalActivitiesAwayComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "My physical activity adapted from a distance",
    "textSection1": "It is essential to physically and mentally stimulate the elderly in institute in all circumstances! <br> <strong> The Covid-19 crisis has shown us that you have to be able to adapt and that other ways of practicing are possible! We have developed remote activities… </strong>",
    "textSection2": "Remote activities are carried out via a « Webcam Tv »: <br> This tool is little known and little used today. <br> However, it has a lot of benefits and more so for the elderly. Directly placed on the TV and connected to the Internet by WIFI, it has a wide-angle lens allowing you to observe all the people sitting in front of the TV. <br> <br> The main advantage of the tool is to be able to carry out video sessions very easily without using a computer, cables or special software.",
    "textSection3": "In addition, the TV webcam also has its own interface. This allows access to other services, including access to video content. <br> This tool will allow us to carry out adapted physical activity programs.",
    "textSection4": "In conjunction with other partners such as ASEPT, HAPPY VISIO or even the French mutual society, this tool will also allow residents to participate in other workshops and conferences",
    "contactUs": "Contact us"
  },
  "fr": {
    "mainTitle": "Mon activité physique adaptée à distance",
    "textSection1": "Il est primordial de stimuler physiquement et mentalement les personnes âgées en institut en toutes circonstances ! <br> <strong> La crise du Covid-19 nous a prouvé qu’il fallait être capable de s’adapter et que d’autres façons de pratiquer étaient possible ! Nous avons développé des activités à distance … </strong> ",
    "textSection2": "Les activités à distance sont réalisées via une « Webcam Tv » : <br> Cet outil est peu connu et peu utilisé aujourd’hui. <br> Cependant, il a énormément d’avantages et d’autant plus pour les personnes âgées. Directement posé sur la télé et connecté à internet en WIFI, il dispose d’un objectif grand-angle permettant d’observer toutes les personnes assises devant la télé. <br><br> L’intérêt premier de l’outil est de pouvoir réaliser des séances en visio de façon très facile sans utiliser d’ordinateur, de câbles ou un logiciel particulier.",
    "textSection3": "De plus, la webcam TV possède sa propre interface. Cela permet d’avoir accès à d’autres services et notamment accès à des contenus vidéos. <br> Cet outil nous permettra de réaliser des programmes d’activité physique adaptée.",
    "textSection4": "En lien avec d’autres partenaires comme l’ASEPT, HAPPY VISIO ou encore la mutualité Française, cet outil permettra également aux résidents de participer à d’autres ateliers et conférences",
    "contactUs": "Contactez nous"
  }
}
</i18n>

<style scoped>

.textBlue {
  color: #57939E;
}

.textGrey {
  color: #808080;
}

.section {
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 50px;
}

.sectionMobile {
  font-size: 14px;
  padding-bottom: 30px;
}

.textSection {
  padding-right: 100px;
}

.contactUsBtn {
  margin-top: 50px;
  margin-left: 50px;
  font-size: 18px;
  text-transform: none;
  width: 400px;
}

</style>
